import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  type = 'tenant';

  constructor(public router:Router) {
    if(localStorage.getItem('token')){

      if(localStorage.getItem('type')) this.type = localStorage.getItem('type');

      this.router.navigate([this.type+'-tabs/']);
    } else {
      this.router.navigate(['tenant-tabs/auth']);
    }
  }
}
